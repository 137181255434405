import React from "react";
import SearchContent from "./Search";

function SearchPage() {
  return (
    <>
      <SearchContent />
    </>
  );
}

export default SearchPage;
